/**
 * Represents a duration of time with days, hours, minutes, seconds, and milliseconds.
 *
 * @remarks
 * The duration should be provided in the format "0:00:00:00.00" where:
 * - The first digit represents days.
 * - The second set of digits represents hours.
 * - The third set of digits represents minutes.
 * - The fourth set of digits represents seconds.
 * - The fifth set of digits represents milliseconds.
 *
 * @example
 * ```typescript
 * const duration = new Duration("0:00:45:59.89");
 * console.log(duration.toString()); // "00:45:59"
 * ```
 * @example
 * ```typescript
 * const duration = new Duration("1:23:45:59.89");
 * console.log(duration.toString()); // "47:45:59"
 * ```
 */
export class Duration {
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  milliseconds = 0;

  constructor(duration: string) {
    const parts = duration.split(":");

    if (parts.length === 4) {
      this.days = parseInt(parts[0], 10);
      this.hours = parseInt(parts[1], 10);
      this.minutes = parseInt(parts[2], 10);

      const secMs = this.splitSecondsMs(parts[3]);
      this.seconds = secMs.seconds;
      this.milliseconds = secMs.milliseconds;
    } else {
      throw new Error("Invalid duration format. Expected '0:00:00:00.00'.");
    }
  }

  private splitSecondsMs(secMs: string): { seconds: number; milliseconds: number } {
    const [seconds, ms] = secMs.split(".");
    return { seconds: parseInt(seconds, 10), milliseconds: parseInt(ms ?? 0, 10) };
  }

  /**
   * Converts the duration object to a string representation.
   *
   * @returns {string} A string in the format "hours:minutes:seconds".
   */
  toString(): string {
    let totalHours = this.hours;
    if (this.days > 0) {
      totalHours += this.days * 24;
    }

    const pad = (num: number): string => num.toString().padStart(2, "0");
    return `${pad(totalHours)}:${pad(this.minutes)}:${pad(this.seconds)}`;
  }
}
