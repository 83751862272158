import { RecordingFileKind, RecordingFileKinds } from "./recording-file-kind";

export class RecordingFile {
  readonly kind: RecordingFileKind;
  readonly uri: string;
  // Includes both the type and subtype (e.g. "text/plain")
  readonly mimeType: string;

  constructor(kind: string, mimeType: string, uri: string) {
    this.kind = this.mapKind(kind);
    this.mimeType = mimeType;
    this.uri = uri;
  }

  get isText(): boolean {
    return this.mimeType.startsWith("text/");
  }

  get isAudio(): boolean {
    return this.mimeType.startsWith("audio/");
  }

  get mimeTypeOnly(): string {
    return this.mimeType.split("/")[0];
  }

  private mapKind(kind: string): RecordingFileKind {
    switch (kind.toLowerCase()) {
      case "audio":
        return RecordingFileKinds.Audio;
      case "text":
        return RecordingFileKinds.Text;
      default:
        return RecordingFileKinds.File;
    }
  }
}
