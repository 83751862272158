import { DetailedRecording, Recording, RecordingFile } from "@shared/models";
import { RecordingResponse, RecordingsResponse } from "@features/activity/activity-grid/models";
import { Duration } from "@shared/models/duration";

export const mapRecording = (recording: RecordingsResponse): Recording => {
  return {
    ...recording,
    duration: recording.duration ? new Duration(recording.duration) : undefined,
  };
};

export const mapDetailedRecording = (recording: RecordingResponse): DetailedRecording => {
  return new DetailedRecording(
    recording.id,
    recording.kind,
    recording.account,
    recording.sender,
    recording.recipients,
    recording.source,
    recording.recordedAt,
    recording.files.map((x) => new RecordingFile(x.kind, x.mime, x.uri))
  );
};
