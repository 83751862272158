import { Source } from "./source";
import { Account } from "./account";
import { RecordingKind, RecordingKinds } from "./recording-kind";
import { RecordingFile } from "./recording-file";
import { RecordingFileKind, RecordingFileKinds } from "./recording-file-kind";

export class DetailedRecording {
  readonly kind: RecordingKind;
  readonly account: Account;
  readonly sender: Account;
  readonly recipients: Account[];
  readonly source: Source;
  readonly recordedAt: Date;
  readonly files: RecordingFile[];

  private readonly id: string;
  private readonly primaryFileKind: RecordingFileKind;

  constructor(
    id: string,
    kind: RecordingKind,
    account: Account,
    sender: Account,
    recipients: Account[],
    source: Source,
    recordedAt: Date,
    files: RecordingFile[]
  ) {
    this.id = id;
    this.kind = kind;
    this.account = account;
    this.sender = sender;
    this.recipients = recipients;
    this.source = source;
    this.recordedAt = recordedAt;
    this.files = files;

    // Does not account for the "unknown" case as of now
    this.primaryFileKind =
      kind === RecordingKinds.Message ? RecordingFileKinds.Text : RecordingFileKinds.Audio;
  }

  /**
   * Gets the primary file associated with the recording based on its kind.
   *
   * If the recording kind is `Message`, it returns the first file of kind `Text`.
   * If the recording kind is `Audio`, it returns the first file of kind `Audio`.
   *
   * @returns {RecordingFile | undefined} The primary file if found, otherwise `undefined`.
   */
  get primaryFile(): RecordingFile | undefined {
    return this.files.find((file) => file.kind === this.primaryFileKind);
  }

  /**
   * Gets the secondary files from the recording.
   *
   * @returns {RecordingFile[]} An array of `RecordingFile` objects that are not of the primary file kind.
   */
  get secondaryFiles(): RecordingFile[] {
    return this.files.filter((file) => file.kind !== this.primaryFileKind);
  }
}
