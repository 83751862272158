import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable, of, tap } from "rxjs";
import { DetailedRecording } from "@shared/models";
import { RecordingResponse } from "@features/activity/activity-grid/models";
import { API_BASE_URL } from "@shared/constants";
import { mapDetailedRecording } from "@shared/helpers";

// When /recording is navigated to it will look for 'recordingId' in the navigation state
// This will have to be included when initiating the navigation
export const RECORDING_ID_KEY = "recordingId";

@Injectable()
export class RecordViewerService {
  private recordingSubject = new BehaviorSubject<DetailedRecording | null>(null);

  data$ = this.recordingSubject.asObservable();

  constructor(
    private http: HttpClient,
    private location: Location
  ) {
    this.loadRecording();
  }

  private loadRecording(): void {
    const navState = this.location.getState() as { [RECORDING_ID_KEY]: string };

    this.getRecordById(navState[RECORDING_ID_KEY])
      .pipe(
        tap((data) => {
          this.recordingSubject.next(data);
        })
      )
      .subscribe();
  }

  private getRecordById(id: string): Observable<DetailedRecording | null> {
    return this.http.get<RecordingResponse>(`${API_BASE_URL}/v2/recordings/${id}`).pipe(
      map(mapDetailedRecording),
      catchError((error) => {
        console.error("Error fetching recording", error);
        return of(null);
      })
    );
  }

  clearRecording(): void {
    this.recordingSubject.next(null);
  }
}
